import React from 'react'
import TextCard from '~/components/molecules/TextCard'
import TextLink from '~/components/atoms/TextLink'
import Image from '~/components/atoms/Image'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function Press({key, index, ...props}) {

  const section = props.press

  return (
    <section id={section.fieldGroupName + index}  className={`my-20 md:my-36 `}>
      <div className="container">
        <div className="grid grid-cols-12 mb-6 md:mb-14">
          <TextCard className="col-span-12 md:col-span-6" 
            {...section.textCard}
          />
        </div>
        <div className="grid grid-cols-12 gap-y-12 sm:gap-y-16 ">
          {section.press.map((item, i) => (
            <>
              <FadeInWhenVisible className="col-span-12 sm:col-span-5">
                <Image data={item.logo} objectFit="contain" objectPosition="bottom left" className="mb-6 w-28 h-auto" />
                <h4 className="text-h4 mb-8">{item.heading}</h4>
                <TextLink {...item.link} />
              </FadeInWhenVisible>
              <div className="hidden sm:block col-span-1"></div>
            </>
          ))}
        </div>
      </div>
    </section>
  )
}
